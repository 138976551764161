// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-markdown-page-templates-basic-template-index-tsx": () => import("./../../src/markdownPageTemplates/BasicTemplate/index.tsx" /* webpackChunkName: "component---src-markdown-page-templates-basic-template-index-tsx" */),
  "component---src-markdown-page-templates-index-page-index-tsx": () => import("./../../src/markdownPageTemplates/IndexPage/index.tsx" /* webpackChunkName: "component---src-markdown-page-templates-index-page-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

